#menu {
    width: 100%;
    justify-content: space-evenly;

    .mobile-menu {
        display: none;
    }

    #our-socials {
        img {
            // -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
            // filter: grayscale(100%);
        }
        #twitter {
            left: 0;
            img {
                margin-top: -5px;
                height: 44px;
            }
        }
        #discord {
            left: 120;
            img { height: 55px; }
        }
        #website {
            left: 150;
            img { height: 47px; }
        }
    }

    #navigation-tabs {
        margin-left: -50px;
        a {
            width: 135px;
            height: 60px;
        }        
        .disabled {
            opacity: 0.5;
            cursor: not-allowed;
        }
        .bottom-menu-buttons {
            background-color: rgba(1,1,1,1);
            font-weight: bold;
        }
        #buttons-separator {
            width: 120px;
        }
        #admin-panel {
            color: orange;
        }
    }

    #logout-tab {
        #app-version {
            margin: 32px 0 0 0;
            color: white;
            font-size: 13px;
        }
        #logout-btn-react {
            float: right;
            height: 60px !important;
            width: 45px;
            background-color: rgba(1,1,1,1);
            padding-left: 10px;
            img {
                margin-top: 20px;
            }
        }
        .menu-icon {
            display: none;
        }
    }
}



@media(max-width:768px) {
    nav {
        background-color: black !important;
        position: absolute;
        bottom: 0;
    }
    #app-version {
        font-size: 12px !important;
        color: darkslategrey !important;
    }
    #menu {
        width: 100%;
        justify-content: space-around;
        > div.d-flex:first-of-type {
            padding-left: 10px;
            margin-left: 0;
            >div {
                display: none;
                min-width: 145px;
                #available-wax {
                    display: none;
                }
            }
        }
        > div.d-flex:nth-of-type(2) {
            margin-left: 0;
            display: none !important;
        }
        >div.d-flex:nth-of-type(3) {
            #logout-btn-react {
                display: none;
            }
            .menu-icon {
                padding-left: 10px;
                display: flex;
                align-items: center;
                :hover {
                    cursor: pointer;
                }
            }
        }
        .mobile-menu {
            display: flex;
            flex-direction: column;
            align-items: center;
            position: fixed;
            box-sizing: border-box;
            background-color:  rgba(6, 7, 13, 0.95);
            border: solid 3px rgb(108, 124, 221);
            width: calc(100vw - 80px);
            height: 210px;
            left: 40px;
            bottom: -600px;
            z-index: 11;
            transition: bottom 0.5s;
            border-radius: 15px;
            padding: 23px;
            .menu-item {
                text-align: center;
                height: 65px;
                border-top: none;
                border-bottom: none;
                text-decoration: none;
                color: #75e2ff;
                display: flex;
                align-items: center;
            }
            .disabled {
                opacity: 0.5;
                cursor: not-allowed;
            }
        }
        .mobile-menu.active {
            bottom: 130px;
        }
    }
}