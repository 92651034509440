.tile-container {
    background-color: black;
    min-height: 180px; 
    margin-bottom: 25px;

    .row.text-center {
        button {
            color: #ffc5ff; 
            font-size: 12px; 
            background-color: #091223;
            border: thin solid #0c1c36; 
            font-weight: bold; 
            min-height: 25px;
            >div {
                font-size: 14px;
            }
        }
        p.mt-2 {
            font-size: 15px;
            a {
                min-height: 100px;
            }
        }
    }

    .tile-img-wrapper:hover, .tile-img:hover {
        cursor: pointer;
        z-index: 1000 !important;
        img {
            transform: translateZ(200px);
            transform: scale(2.7);
            background-color: black;
            box-shadow: 0 0 1px 6px rgba(134, 233, 255, 0.8);
            border-radius: 2px;
            z-index: 2000 !important;
        }
    }
}
