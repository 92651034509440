.resources-payment {
    #resources {
        overflow: auto;
    }
    .inner-container {
        text-align: center;
        padding: 20px;

        .buy-alcor {
            color: white;
            font-size: 14px;
            margin-top: -5px;
        }

        #food-column {
            background-color: rgba(1,1,1,0.8);
            padding: 10px;
        }
        #spaceship-column {
            background-color: rgba(1,1,1,0.8);
            padding: 10px;
        }

        #characters-feed {
            color: #50bbb2;
            font-size: 14px;
            font-weight: bold;
            margin-top: -25px;
        }
    }
    
    #loading-map-0 {
        width: 80%;
        opacity: 0.8;
        margin-top: 40px;
        margin-bottom: 40px;
    }

    #time-to-destination {
        text-align: center;
        background-color: rgba(71, 98, 192, 0.6);
        color: black;
        font-family: Monospace;
        border-radius: 20px;
        font-size: 18px;
        #countdown {
            font-size: 22px;
        }
    }

    #bottom-pane {
        #lightspeed-travel {
            font-size: 32px;
            padding: 30px !important;
        }
    }
}
