
.ActiveTourneyMsg {
    p {
        position: absolute;
        bottom: 20px;
        right: 50px;
        font-size: 22px;
        color: black;
        font-weight: bolder;

        background-color: rgba(230, 230, 230, 0.3);
        padding: 5px;
        box-shadow: 0 0 14px 16px rgba(230, 230, 230, 0.3);
    }
}