#game-overlays {
    position: absolute;
    top: 280px;
    left: 10px;
    width: 70px;
    z-index: 1000;
    min-height: 100px;
    max-height: 500px;
    overflow: auto;
    box-shadow: 0 0 6px 1px rgba(224, 224, 224, 0.4);
    padding-top: 5px;
    text-align: center;
    font-size: 8px;
    z-index: 998;
    
    #title {
        font-size: 11px;
        font-style: oblique;
        font-weight: bold;
        margin-bottom: 15px;
        a {
            color: aqua;
            text-decoration: none;
        }
    }

    img {
        width: 60px;
        margin-bottom: 10px;
        margin-left: 2px;
    }
}
