#storage-panel {
    background-color: rgba(1,1,1,1);
    width: 50px;

    #storage-menu-button {
        margin-left: 5px;
        height: 60px;
        :hover {
            cursor: pointer;
            background-color: rgba(213, 215, 167, 0.2) !important;
            transform: translate(-0%, -30%);
            // MAKE IT BIGGER @@
        }

        #scifi-storage {
            height: 50px;
            margin-left: 5px;
        }

        #color-bar {
            position: absolute;
            top: 50px;
            width: 38px;
            height: 6px;
        }
        img {
            margin-top: 2px;
        }
    }

    #full-screen-overlay {
        position: fixed;
        top: 0;
        left: 0;
        background-color: rgba(240, 220, 200, 0.3);
        z-index: 1000;
        width: 100%;
        height: 100%;
        border: 'thin solid red';
    }

    #storage-overlay {
        z-index: 2000;
        background-color: black !important;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 550px;
        height: 180px;
        background-color: black;
        margin-left: -60px;
        color: white !important;
        font-size: 16px;
        cursor: auto;

        #purchase-options {
            margin-top: 10px;
            .buy-sub {
                height: 30px !important;
            }
            .purchase-for {
                font-size: 14px;
            }
            .using {
                font-size: 13px;
                font-style: italic;
                margin-top: 5px;
                margin-bottom: 5px !important;
            }
            #expired-warn {
                font-size: 13px;
                font-style: italic;
                color: red;
                font-weight: bold;
            }
        }

        #storage-title {
            font-size: 18px !important;
            color: aqua;
            font-weight: 600;
        }

        button {
            width: 180px;
        }
        button:hover {
            cursor: pointer;
        }
        
        .nested-box {
            border: thin solid grey;

            #upgrade-btn {
                float: right;
                font-size: 14px;
                color: #0d6efd;
                cursor: pointer;
            }
        }
        
        .tooltip-info {
            cursor: pointer;
        }

        #paid-resources {
            font-size: 15px;
            font-style: italic;
            padding: 2px;
            margin: auto;
            margin-top: 10px !important;
            max-width: 340px;
            .boldo { color: greenyellow }
        }

        #buy-mars {
            text-align: left;
            a {
                text-decoration: none;
            }
        }

        #close-storage-overlay {
            position: absolute;
            top: 2px;
            right: 15px;
            color: white;
            font-weight: 700;
            cursor: pointer;
        }
    }
}