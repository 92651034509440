#login-choice-container {
    padding-left: 40px;

    #back-to-game-mode {
        float: right;
        font-size: 20px;
        margin-top: -35px;
        cursor: pointer;
        font-size: 16px;
    }

    .login-options-btns {
        margin-left: 20px;
        width: 105px;
        font-size: 18px;
    }
    .button-choices-wrapper {
        width: 80%;
        margin: auto;
        margin-left: 90px;
        img {
            margin-top: 20px;
            margin-left: 20px;
        }
    }
    #accept-terms {
        padding-left: 40px;
        #terms-wrapper {
            padding-bottom: 20px;
        }
        #terms-text {
            text-align: left;
            font-size: 16px;
            margin-left: -10px;
            a {
                outline: none;
                box-shadow: none !important;
                border: none;
            }
        }
        #choice-close-btn {
            float: right;
            color: white;
            margin-top: -25px;
            cursor: pointer;
            font-size: 16px;
            z-index: 100;
        }
        #tac-checkbox-wrap {
            text-align: right;
            margin-left: -15px !important;
            padding-bottom: 10px;
            outline: none;
            box-shadow: none !important;
            >span {
                outline: none;
                box-shadow: none !important;
            }
            .active {
                background-color: rgb(23, 129, 120);
            }
            .disabled {
                background-color: gray;
            }
            #tac-checkbox {
                margin-top: 25px;
            }
        }
    }
    #login-wrapper {
        margin-bottom: -10px;
        padding-left: 120px !important;
        #hive-username-input {
            margin-right: 100px;
            margin-left: -100px;
            width: 150px;
            color: black !important;
            font-size: 16px;
        }
        input:placeholder-shown {
            border: 2px solid black;
        }
        input::placeholder {
            color: black !important;
        }
        #login-btn {
            color: white;
            margin-left: -100px;
            margin-top: 10px;
            box-shadow: 0 0 8px 12px rgba(230, 230, 230, 0.3);
            background-color: #009ab6;
            width: 180px;
            height: 58px;
            font-size: 18px;
            margin-bottom: 10px;
            padding: 15px;
        }
        #login-btn.disabled {
            background-color: grey;
        }
        .hive-login {
            max-width: 160px;
            outline: none;
            border: none;
            padding: 5px;
            border-radius: 5px;
        }
        .skl-login {
            width: 220px !important;
            outline: none;
            border: none;
            padding: 5px;
            border-radius: 5px;
        }
        #hive-login-error {
            font-size: 14px;
            color: rgb(255, 70, 70);
            background-color: black;
            padding: 10px;
        }
        #keychain-installer {
            color: rgb(255, 70, 70);
        }
    }
    #sign-up {
        padding-top: 5px;
        font-style: italic;
        font-size: 14px;
        margin-left: 80px;
        #signup-cta { font-size: 14px; }
    }

    #eth-network-choice-wrapper {
        #eth-choice-back {
            cursor: pointer;
            pointer-events: all;
            margin-top: -25px;
            float: right;
            width: 100px;
            font-size: 16px;
            color: white;
        }
    }
}
