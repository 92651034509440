.container.wallet {
    max-width: 80%;
    font-size: 16px;
    #wallet-wrapper {
        font-size: 18px;
        #pre-ss-stake {
            color: greenyellow;
            font-size: 16px;
            font-weight: bold;
        }
    }
    .nav-item {
        font-size: 16px;
    }
    >div>div.row {
        box-shadow: 0 0 4px 8px rgba(255,255,255,0.2);
        background: url(https://files.peakd.com/file/peakd-hive/cryptoshots.nft/EoCgdfRTsjmiVK87nnsrZPRGAff9skg6K5T27c3rndUkKP3UT7iBCRTht9GRfEcDdwv.png) no-repeat center;
        // background-size: 100%;
        height: 85vh;
        padding: 20px;
        background-color: rgba(13, 110, 253, 0.25);
        overflow-y: auto;
        display: flex;
        align-items: center;
        .container-left {
            background-color: rgba(55, 65, 154, 0.95);
            border-radius: 5px;
            padding: 15px;
            #wax-send-wrap {
                font-size: 14px !important;
                input {
                    font-size: 14px;
                }
            }
            h4, p {
                font-size: 16px;
            }
            h6 {
                font-size: 18px;
            }
            #send-wax-btn {
                margin-top: 0px;
            }
            >div.col-12 {
                p.mt-3.text-white:first-of-type {
                    font-size: 16px;
                }
                p.mt-3.text-white:nth-of-type(2) {
                    font-size: 12px;
                }
            }
            #recipient-wax {
                width: 120px;
            }
            #memo-wax {
                width: 160px;
            }
            #amount-wax {
                width: 120px;
                margin-top: 5px;
            }
            .send-wax {
            }
        }
        >div.col-md-6:nth-of-type(2) {
            >div.col-12.mt-4 {
                background-color: rgba(55, 65, 154, 0.9);
                border-radius: 5px;
                h6 {
                    font-size: 18px;
                }
                div.col-12.mt-4 {
                    >label:first-of-type {
                        width: 120px;
                    }
                    >label:nth-of-type(2) {
                        width: 120px;
                        margin-top: 5px;
                    }
                    #recipient-boom, 
                    #amount-boom,
                    #recipient-mars,
                    #amount-mars {
                        margin: 0 10px;
                    }
                }
            }
        }
    }

    a {
        color: rgb(170, 164, 247) !important;
    }
}

@media(max-width:768px) { 
    .container.wallet {
        max-width: 95%;
        >div>div.row {
            overflow-y: scroll;
            background-size: 300%;
            > div.col-md-6:first-of-type {
                height: unset;
            }
        }
    }
}
