.pending-rewards {
    margin-top: 20px;

    #expand-token {
        color: white;
        font-size: 22px;
        float: right;
        background: none;
        border: none;
        outline: none;
    }

    .pen-rew-title {
        margin-bottom: 0px;
        font-weight: bolder;
    }
}