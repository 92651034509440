#anon-banner {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    width: 50%;

    iframe {
        width: 728px;
        height: 90px;
        border: 0px;
        padding: 0;
        overflow: hidden;
        background-color: transparent;
    }
    a {
        font-size: 12px;
        font-style: italic;
        position: absolute;
        bottom: 97px;
        color: white;
        margin-left: -170px;
    }
}

#anon-square {
    position: fixed;
    right: 10px;
    top: 10px;
    iframe {
        width: 125px;
        height: 125px;
        border: 0px;
        padding: 0;
        overflow: hidden;
        background-color: transparent;
    }
}
