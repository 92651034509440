.left-menu {
    background-color: rgba(1,1,1,1);
    max-width: 320px !important;
    height: 60px;

    #cs-logo {
        margin-left: -20px;
        margin-top: 5px;
        height: 50px;
        width: 50px;
    }

    #name-and-balance {
        min-width: 160px;
        margin-left: -20px;
        a {
            text-decoration: none !important;
        }
        a:hover {
            color: white;
        }

        #username {
            text-align: center;
            border-radius: 10px;
            padding: 5px;
            min-width: 140px;
            max-width: 160px;
            font-size: 16px;
        }

        #wallet-balance {
            margin-top: -2px;
            color: white !important;
            font-size: 13px;
            text-align: center;
            a {
                padding: 5px;
            }
            :hover {
                font-weight: bolder;
                cursor: pointer;
            }
        }
    }

    #which-chain {
        margin-right: 10px;
        margin-top: 5px;
    }
}