.welcome {
    .row {
        padding-bottom: 5%;
        .shade {
            display: flex;
            margin: -10px;
            font-size: 20px;
        }
    }
    .hot-items {
        .cooldown-msg {
            color: yellow;
            font-weight: bold;
        }
        .new-mints-msg {
            font-style: italic;
        }
    }
    .new-line {
        display: none;
    }
    #litepaper-links {
        text-align: 'left';
        background-color: rgba(0,0,0,0.5);
        border-radius: 150px;
        padding: 20px;
        font-size: 16px;
        h4 {
            font-size: 22px;
        }
    }
    .new-player-sell {
        margin-top: 30px;
        a {
            text-decoration: none;
        }
        #nft-sales-container {
            max-width: 700px;
            margin: auto;
            #stuff-list {
                border: 5px solid rgba(10,42,55,0.2);
                background-color: rgba(20,34,45,0.7);

                padding: 10px
            }
            #call-to-action {
                margin-top: 50px;
                border: 5px solid rgba(10,42,55,0.2);
                background-color: rgba(20,34,45,0.7);
                padding: 15px;
                a { text-decoration: underline; }
                button {
                    margin: 15px auto 25px;
                    font-size: 20px;
                }
            }
        }
    }
    #wp {
        color: yellow;
        font-weight: bold;
    }
}
.underlined {
    text-decoration: underline;
}


@media(max-width:768px) { 
    .welcome {
        margin-top: -20px !important;
    }
    .underlined {
        display: block !important;
    }
    .new-line {
        display: block !important;
        margin-top: -20px;
    }
    .new-player-sell {
        #nft-sales-container {
            max-width: 450px;
        }
    }
}
