.category-container {
    >div.head {
        padding: 2px;
        border-radius: 0;
        font-size: 18px; 
        background-color: rgba(255,255,255,0.5);

        div.col-6:first-of-type {
            color: black;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        div.col-6:nth-of-type(2) {
            padding-bottom: 5px;
        }
        sub>b {
            color: #ffc83d;
        }

        .category-schemas {
            text-decoration: none;
            color: rgb(31, 31, 73);
            font-size: 13px;
            font-weight: bold;
        }

        .missing-items-tab {
            outline: none; 
            border: none;
            height: '34px'; 
            margin-bottom: -15px;
            background-color: rgba(200, 15, 9, 0.764); 
            box-shadow: 0 0 5px 8px rgba(255, 52, 52, 0.752);
            border-radius: 2px;
            padding: 0px; 
            width: 155px;
            color: black;
            >span {
                font-size: 14px;
                .missing-items-count {
                    color: #c8f110;
                    font-weight: bold;
                }
            }
        }
        .tab-open {
            background-color: rgba(224, 224, 224, 0.764); 
            box-shadow: 0 0 5px 8px rgba(87, 59, 59, 0.752);
        }
        .you-got-all {
            background-color: rgba(24, 239, 110, 0.713);
            box-shadow: 0 0 5px 8px rgba(51, 210, 69, 0.8);
            border-radius: 2px;
            padding: 2px;
            color: rgb(1, 47, 1);
            font-size: 14px;
            float: right;
        }
        .missing-items-btns {
            font-weight: bold;
            padding-right: 0px;
            padding-left: 0px;
        }
    }
    >div.row {
        div.col-6.mt-2 {
            margin-bottom: 25px;
            >sub {
                color: black;
                font-size: 14px;
                font-weight: bold;
            }
        }
    }
}

@media(max-width:768px) { 
    .category-container {
        >div.head {
            div.col-6:nth-of-type(2) {
                padding-bottom: 0;
                padding-right: 0;
                .missing-items-tab {
                    height: 100%;
                    margin-bottom: 0;
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
    }
}