.hive-inventory-container {
    text-align: center;
    .black-shade {
        background-color: rgba(1,2,20,0.7);
        line-height: 14px;
    }
    #nft-from { font-size: 13px; }
    .vertical-spread { line-height: 18px !important; }
    .buy-more {
        font-size: 18px;
        border-left: 2px solid rgba(227, 19, 55, 0.3);
        background-color: rgba(1,2,20,0.9);
        padding: 10px;
        text-align: center;
        line-height: 22px;
    }
    .hive-scholarship {
        font-size: 16px;
        border: 1px solid rgba(227, 19, 55, 0.8);
        margin-left: 20px;
        margin-top: 5px;
        margin-bottom: -30px;
        height: 135px;
        padding: 2px !important;
        line-height: 18px;
        text-align: center;
        .notes {
            padding: 3px;
        }
        #example-id {
            color: yellow;
            font-size: 14px;
        }
    }
    .pull-skirt-up {
        margin-top: -8px;
        margin-bottom: 0px;
    }
    .pad { margin-top: -5px; }
    #teacher-memo {
        font-size: 16px;
        text-align: center;
        margin-top: 8px;
    }
    #tease-ss {
        margin-top: 10px;
        font-size: 22px;
    }
    #ss-instructions { margin-top: 25px; }
    .sticky { margin-left: -4px; }
    .section-title {
        margin-top: 30px;
    }
    .instructions-title {
        margin-bottom: 0px;
        padding-bottom: 5px;
    }
    .fixed-header {
        position: fixed;
        background-color: black;
        border-bottom: thin solid white;
        z-index: 2000;
        left: 50%;
        top: 50px;
        width: 90%;
        transform: translate(-50%, -50%);
    }
    #hive-inventory-title {
        font-size: 22px !important;
        padding-top: 20px;
        #nft-inventory-label {
            font-size: 36px;
            margin-right: 60px;
        }
    }
    .buy-more {
        font-size: 16px;
    }
    .hive-nft-tile {
        border-right: thin solid rgba(227, 19, 55, 0.4);
        border-bottom: thin solid rgba(227, 19, 55, 0.8);
        background-color: rgba(255, 255, 255, 0.02);
        border-bottom-right-radius: 15px;
        .nft-image-wrap {
            div {
                position: relative;
                height: 170px;
            }
        }
        .nft-image-wrap:not(:hover) {
            div {
                overflow: hidden;
                img {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    -ms-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
                }
            }
        }
        .nft-image-wrap:hover {
            div {
                z-index: 1000;
                img {
                    transform: translateZ(2000px) translateY(80px) scale(2.5);
                    -ms-transform: translateY(80px) scale(2.5);
                    background-color: black;
                    box-shadow: 0 0 1px 6px rgba(134, 233, 255, 0.8);
                    border-radius: 2px;
                }
            }
        }
        #tile-details {
            line-height: 14px;
            #mint-and-name {
                margin-top: 10px;
                font-size: 18px;
                line-height: 12px;
            }
            #nft-details {
                margin-top: -10px;
                font-size: 14px;
                padding: 4px;
            }
        }
    }

    .hive-nfts-panel {
        margin-top: 5px;
        padding: 2px;
        img {
            display: block;
            margin: auto;
        }
        .hive-nft-tile {
            width: 200px;
            margin: 10px auto 0px 10px;
            display: inline-block;
            #tile-details {
              line-height: 6px !important;
              .decent-lines {
                line-height: 16px;
              }
            }
            .nft-image {
                margin-bottom: 20px;
            }
        }
        .schema {
            margin-top: 20px;
        }
        .li-elem { margin-top: 30px; }
        .li-last { margin-top: 10px; margin-bottom: 25px; }
        a {
            color: white;
            font-weight: bold;
        }
        #ah-code-example { font-size: 20px; }
        #no-hive-nfts {
            padding: 10px;
            font-size: 22px;
        }
        #disclaimer-sell {
            padding: 10px;
            font-size: 14px;
        }
        .category-name {
            padding: 8px;
            color: black;
            font-weight: bolder;
            border-radius: 0;
            font-size: 18px; 
            background-color: rgba(255,255,255,0.5);
        }
    }
}

.bolder {
    font-weight: bolder;
}
