#claim-disclaimer-container {
    font-size: 14px;

    #rules-and-max-claims {
        margin-top: 0px;
        padding: 3px;
        padding-bottom: 0px;
        border: thin dashed;
        border-color: rgba(244, 242, 94, 0.6);

        #your-max {
            color: #298ae5;
            margin-top: 10px;
            font-size: 16px;
            .your-max-value {
                font-size: 20px;
            }
        }
        #unlock-it {
            margin-bottom: 10px;
            color: rgb(193, 193, 56);
        }
        #uh-oh {
            color: red;
            font-weight: bold;
            background-color: rgba(10, 10, 10, 0.4);
            border-radius: 6px;
            padding: 5px;
        }
        .advice {
            color: white;
        }
    }

    .orange {
        color: orange;
    }
    .big {
        font-size: 22px;
    }
}


// resProcessClaimBoom,
// resProcessClaimMars,
// resProcessClaimDoom,