
#PvpPrizes {
    #prizes-main-title {
        font-size: 22px;
        background-color: black;
        color: white;
        width: 80%;
    }
    #pvp-banner {
        img {
            object-fit: cover;
            width: 100%;
        }
    }

    .tourney-group {
        background-color: black;
        color: white;
        padding: 10px;
        .tourney-category {
            font-size: 24px;
            .sponsor {
                float: right;
                font-size: 20px;
                color: blueviolet;
                width: 40%;
                text-align: left;
                margin-top: 0px;
            }
        }
        .tourney-tile {
            p {
                margin-top: -10px;
            }
        }
    }
    .green {
        color: greenyellow
    }
    .red {
        color: red;
    }
}