#landing {
    text-align: center;
    #p2e-game, #try-game-free {
        max-width: 900px;
        min-height: 150px;
        border: thin solid rgba(200,200,200,0.1);
    }
    #p2e-game {
        margin-top: 30px;
        padding-top: 35px;
        &.waiting-choice {
            height: 100px;
        }
    }
    .not-logged-pane {
        height: 210px;
    }
    .shade {
        padding: 20px;
        background-color: rgba(1,1,1,0.95);
        color: white !important;
    }

    #logo {
        width: 600px;
    }

    .landing.container {
        #outage-msg {
            padding: 40px;
            color: red;
            background-color: rgba(1,1,1,0.9);
        }
        #terms-wrapper {
            width: 400px;
            margin: -20px auto -20px;
        }
        .pc-loaded {
            font-size: 13px;
            float: right;
            margin-right: 20px;
            margin-top: -10px;
        }
        .free-wrapper {
            margin: auto;
        }
        #login-wait-panel {
            height: 300px;
            h5 {
                text-shadow: 2px 2px rgb(126, 29, 186);
                font-size: 20px;
                line-height: 44px;
            }
            .boldo {
                font-size: 38px;
            }
        }
        #please-reload {
            font-size: 18px;
            width: 100%;
            margin-left: -70px;
            .reload {
                font-weight: bold;
                text-decoration: underline;
                cursor: pointer;
                color: rgb(29, 79, 205);
            }
        }
    }
    h3 {
        font-size: 14px;
        >button {
            color: #231f20;
            background-color: gray;
            cursor: not-allowed;
            border: none;
            border-radius: 5px;
            padding: 20px;
            margin-top: 10px;
        }
        >button.active {
            color: black;
            background-color: rgb(19, 84, 78);
            cursor: pointer;
        }
    }
    h6 {
        font-style: italic;
        font-size: 14px;
    }
}

.logged {
    background: url(https://files.peakd.com/file/peakd-hive/cryptoshots.nft/23w2aHrokTpEbRNXaQg2n3Msq3wwukXutwUp5Hw5GSzHt24iwNBoS9rZicwZcaanC8AFV.gif) no-repeat center;
    background-size: cover;
    height: 450px;
}
.not-logged {
    background-color: rgb(3, 14, 10);
}
#banners {
  position: fixed;
  bottom: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  pointer-events: none;

  .banner-content {
    display: flex;
    align-items: center;
    width: 90%;
    max-width: 800px; // Set a reasonable max width
    pointer-events: all;

    img {
      width: 100%;
      height: auto; // Maintains aspect ratio
      background-color: rgba(224, 224, 104, 0.5);
      padding: 0;
      box-shadow: 0 0 4px 2px rgba(224, 224, 224, 0.4);
    }

    button {
      // Adjust button styles as needed
      height: 25px;
      width: 25px;
      outline: none;
      border-radius: 20px;
      border: none;
      font-size: 12px;
      color: rgb(50, 50, 1);
      margin-left: -5px;

      p {
        transform: scale(1.4, 1);
        margin-top: -2px;
        margin-right: -1px;
      }

      color: #ff0055;
      font-size: 18px;
      font-weight: bold;
      cursor: pointer;
      padding: 0;
      background-color: black;

      &:hover {
        color: #ff3366;
      }
    }
  }
}
  
#try-game-free, #p2e-game-play {
    margin: auto;
    margin-top: 40px;
    margin-bottom: 40px;
    .free-trial {
        margin-top: 15px;
        height: 70px;
        background-color: #009ab6;
        padding: 15px 20px;
        border-radius: 5px;
        outline: none;
        border: none;
        box-shadow: 0 0 14px 16px rgba(230, 230, 230, 0.3);
        font-size: 18px;
    }
    #no-login {
        margin-top: 30px;
        font-style: italic;
        font-size: 16px;
        text-align: center;
        color: white;
        b {
            text-decoration: underline;
        }
    }
}
#p2e-game-play {
    margin-top: -10px;
    #no-rewards-p2e-pvp {
        text-align: right;
        margin-left: -130px;
        margin-top: 8px;
        font-size: 12px;
    }
}


@media(max-width:768px) { 
    .landing.container {
        width: 95%;
        padding: 10px;
        > div:first-of-type > div > img {
            width: 80%;
        }
    }
    #banners {
        img {
            position: absolute;
            bottom: 90px;
            left: 2%;
            width: 96% !important;
        }
    }
}