.admin-panel {
    background-color: black;
    padding: 60px;
    height: 92vh;
    overflow: auto;
    font-size: 14px;
    button {
        color: black;
        font-size: 16px;
    }
    #username {
        padding: 5px;
        margin-top: -30px;
    }
    .section-title {
        color: white;
        padding: 5px;
    }
    .pad {
        padding: 20px;
    }
    .ui-message {
        background-color: darkolivegreen;
        color: white;
        padding: 5px;
        border: thin solid yellow;
    }
    #rank-resets-failures, #transfers-failures {
        background-color: darkblue;
        color: red;
        padding: 5px;
        border: thin solid red;
        height: 350px;
        overflow: auto;
    }
    .disabled {
        background-color: grey;
        cursor: not-allowed;
    }
}
