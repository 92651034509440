.suggest-brave {
    color: #e0e068;
    margin: 40px auto 0;
    background-color: rgba(20, 20, 20, 0.95);
    padding: 16px;
    max-width: 740px;
    text-align: left;
    border: 1px solid #444;
    box-shadow: 0 0 20px rgba(246, 170, 17, 0.8);
    position: relative;
  
    h4 {
      font-size: 16px;
      position: relative;
      margin: 0;
  
      .warning-icon {
        width: 85px;
        height: 70px;
        vertical-align: middle;
        margin-right: 8px;
      }
  
      span {
        font-size: 20px;
        display: inline-block;
        vertical-align: middle;
        margin-bottom: 8px;
        text-align: left;
      }
  
      a {
        color: #00ffff;
        text-decoration: none;
  
        &:hover {
          text-decoration: underline;
        }
      }
  
      .close-button {
        position: absolute;
        top: -10px;
        right: -5px;
        background: none;
        border: none;
        color: #ff0055;
        font-size: 24px;
        cursor: pointer;
        padding: 0;
  
        &:hover {
          color: #ff3366;
        }
      }
    }
  }
  