.inventory-container {
    max-width: 80% !important;
    #refresh-section {
        font-size: 14px;
        .mt-4 {
            z-index: 100;
            margin-bottom: -40px;
            #refresh {
                border-radius: 30px;
                float: right;
                margin-right: 30px;
            }
        }
    }
    .inner-container {
        z-index: 0;
        box-shadow: 0 0 4px 8px rgba(255,255,255,0.2);
        background: url(https://files.peakd.com/file/peakd-hive/cryptoshots.nft/23tRrRNpe54vXExfQNRmD3CdmfD8t7wW2PhpBaBUDbBjcRJXVczwSnTJuZGqcc6f3vHrY.gif) no-repeat center;
        background-size: 100%;
        // height: 85vh;
        background-color: black;
        margin-top: 10px;
        .scholarships-pane {
            font-size: 16px;
            color: rgb(10, 18, 70);
            text-align: center;
            margin-bottom: -20px;
            background-color: rgba(141, 240, 159, 0.8);
            padding-top: 0;
            a {
                color: rgb(13, 61, 13);
            }
        }
        .some-name-wrapper {
            border-radius: '5px';
            overflow-y: auto;
            .inventory-top-panel {
                width: 90%;
                // margin-top: -40px;
                padding: 10px;
                background-color: rgba(1,1,1,0.5);
                >div.col-6.text-center {
                    >div:first-of-type {
                        margin-top: -5px;
                        #tot-nfts {
                            font-size: 24px;
                        }
                    }
                    >div:nth-of-type(2) {
                        margin-top: -10px;
                        >div:first-of-type {
                            p {
                                font-size: 15px;
                                margin-bottom: 25px;
                            }
                        }
                        >div:nth-of-type(2) {
                            margin-top: -10px;
                            p {
                                padding-bottom: 30px;
                                max-width: 350px;
                                margin: auto;
                                font-size: 14px;
                            }
                        }
                    }
                }
                >div.col-6:nth-of-type(2) {
                    #ah-link {
                        float: right;
                        padding: 5px;
                        font-size: 16px;
                        font-weight: bold;
                        text-align: center;
                        background-color: rgba(33, 53, 100, 0.8);
                        border-radius: 5px;
                        a {
                            color: #428bf8;
                            text-decoration: none;
                        }
                    }
                    >span {
                        font-size: 15px;
                    }
                }
            }
            h5 {
                padding: 10px;
                font-size: 20px;
                text-align: center;
                padding-top: 40px;
            }
        }
        .alcor-buy-boom {
            text-decoration: none;
            font-size: 22px;
        }
        .boom-for-purchases {
            padding: 20px;
            h4 {
                font-size: 18px;
            }
        }
        .total-nfts {
            padding: 15px;
        }
        #boom-purchases-title {
            font-size: 20px;
        }
        #newstarter-msg {
            line-height: 16px;
        }
    }

    .instructions-panel {
        margin-top: -20px;
        max-width: 600px;
        margin: auto;
        background-color: rgba(227, 19, 55, 0.9);
        text-align: center;
        .reload {
            font-weight: bold;
            text-decoration: underline;
            cursor: pointer;
        }
        #notes {
            margin: 0px auto -15px 30%;
            padding: 5px;
            border: thin solid black;
            max-width: 700px;
            min-height: 60px;
            font-size: 16px;
            line-height: 20px;
        }
        h4 {
            font-size: 24px !important;
        }
    }
    .mc-wrapper {
        font-size: 14px;
        margin-top: -15px;
    }
}
.loading {
    padding: 10px;
}



@media(max-width:768px) { 
    .inventory-container {
        max-width: 95%;
        padding: 0 !important;
        #refresh {
            display: none !important;
        }
    }
    .inner-container {
        background-size: cover !important;
        height: 90vh !important;
        .shade {
            padding: 20px 0 20px 0 !important;
        }
    }
    .alcor-buy-boom {
        font-size: 18px !important;
    }
    #ah-link, #boom-purchases-title, #hot-nfts {
        display: none;
    }
    .boom-for-purchases, .total-nfts {
        padding: 0 !important;
        text-align: center;
    }
    .total-nfts {
        span:nth-of-type(1) {
            display: none;
        }
    }
}
