.text-left {
    text-align: left;
}
.container.leaderboard {
    max-width: 80%;
    font-size: 14px;
    >div {
        box-shadow: 0 0 4px 8px rgba(255,255,255,0.2);
        background: url(https://files.peakd.com/file/peakd-hive/crypto-shots/23tRzWPsJif3sJvj5RTt7H3aZ5xuobJEcRmLMPPMF6AzF285iEE22W8YtEeGUPwmRNxSx.gif) no-repeat center;
        background-size: cover;
        height: 87vh;
        padding: 10px 30px;
        background-color: #311b1d;
        overflow-y: auto;
        h1.title {
            margin: 15px auto;
        }
        .your-position {
            margin: 10px auto;
            // margin-top: -40px;
            background-color: rgba(0, 0, 0, 0.8);
            padding: 5px;
        }
        .nav-link {
            color: white;
        }
        .nav-link.active {
            color: #495057;
        }
        .top-ranked td {
            color: gold;
            font-weight: bold;
        }
    }
    .tab-content {
        .tab-pane {
            display: none !important;
            opacity: 0;
        }
        .active {
            display: block !important;
            opacity: 1;
        }
    }

    .thin-column {
        max-width: 800px;
        text-align: center;
        margin: auto;
    }

    .nav {
        background-color: rgba(1,1,1,0.7) !important;
    }
}


@media(max-width:768px) { 
    .container.leaderboard {
        .your-position {
            display: none;
        }
    }
}
