.LeaderboardPrizes {
    max-height: 400px;
    
    #prizes-main-title {
        font-size: 22px;
        background-color: black;
        color: white;
        width: 80%;
    }

    .prize-tile-wrapper {
        background-color: black !important;
        color: white;
        height: 100%;
    }

    #week-and-month-prizes {
        #week-prizes {
        }
        #month-prizes {
            padding: 0px 10px 10px;
        }
    }
    #year-prizes {
        margin-top: 10px;
    }
    
    .prize-tile {
        padding: 5px;
        .prize-category {
            margin-bottom: 0px;
            font-size: 24px;
            .tile-prize-sum {
                font-size: 14px;
                margin-left: 15px;
                display: inline-block;
                color: green;
                font-style: italic;
                font-weight: bold;
                transform: scale(1.2, 2);
                .loading {
                    font-style: italic;
                    font-size: 10px;
                }
            }
        }
        .paragraphs-wrapper {
            margin-top: -5px;
            font-size: 15px;
            line-height: 18px;
        }
        .bottom {
            margin-top: -5px;
        }
        .SponsorPrizesPanel {
            margin-top: 20px;
            .sponsor-name {
                font-size: 16px;
            }
            .sponsored-by-label {
                font-size: 13px;
            }
            .placeholder-wrapper {
                margin-top: 30px;
                padding: 10px 10px;
            }
        }
        #big-banner-wrapper {
            margin-top: 10px;
            padding: 15px;
        }
        #sponsor-big-banner-wrapper {
            padding: 0 30px 20px;
        }
        .usd-prize {
            font-size: 18px;
            font-weight: bold;
            color: yellow
        }
        .ad-price-label {
            text-align: center;
            margin-top: 10px;
            .ad-price {
                transform: scale(1, 1.2);
                display: inline-block;
                font-weight: bold;
                color: #f03a17;
            }
            .token-accepted {
                margin-top: -35px;
                text-decoration: underline;
            }
        }
    }
}