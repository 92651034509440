.play.container {
    max-width: 80%;
    .inner-container {
        box-shadow: 0 0 4px 8px rgba(255,255,255,0.2);
        background: url(https://files.peakd.com/file/peakd-hive/cryptoshots.nft/23tGcEDypTNXdJZMq1spBZycuAQj8vFa77ZiBkniDZL5tk1AthYkGwaVWxMZygQFNp6cT.png) no-repeat center;
        // background-size: 100%;
        font-size: 16px;
        padding: 20 80px;
        height: 85vh;
        background-color:  rgba(1, 6, 7, 0.9);
        overflow-y: auto;
        display: flex;

        #player-stats {
            font-size: 16px;
        }

        > div.col-md-6:first-of-type {
            .play-btn-wrap {
                margin-bottom: 40px;
                text-align: center;
                line-height: 10px;
                #results-wrapper {
                    margin: auto;
                }
                #newstarter-msg {
                    color: #7bffff;
                    line-height: 18px;
                }
                #play-button {
                    padding: 40px;
                    font-size: 30px;
                    border-radius: 30px;
                    width: 200px;
                    @media(max-width:768px) { 
                        width: 135px;
                        font-size: 18px;
                    }
                }
                #resources-button {
                    height: 100px;
                    width: 140px;
                    font-weight: bold;
                }
                #play-btn {
                }
                #no-required-nfts {
                    color: red;
                    font-size: 22px;
                    margin-bottom: 25px;
                }
                #time-reset {
                    line-height: 14px;
                    margin-top: 10px;
                }
            }
            .game-result {
                color: lightgreen;
                font-size: 18px;
                background-color: rgba(18, 6, 25, 0.9);
                border-radius: 5px;
                padding: 10px;
                #level-number {
                    font-size: 32px;
                    color:rgb(111, 52, 133);
                    font-weight: bolder;
                }
                .same-token {
                    margin-top: -15px;
                }
                sub {
                    >p {
                        margin-top: 25px;
                    }
                    .m-t-40 {
                        margin-top: 40px;
                    }
                    .light-blue {
                        color: lightblue;
                    }
                    .match-id-ui {
                        font-size: 12px;
                        color: white;
                    }
                }
                #boom-staked, #time-used {
                    line-height: 17px;
                }
                #time-used {
                    font-size: 13px;
                }
            }
            .game-outcome {
                text-align: left;
                line-height: 12px;
                font-size: 15px;
                height: 100%;
                color: white !important;
                .entry-title {
                    color: rgb(122, 219, 81);
                    font-size: 16px;
                    font-weight: bold;
                }
                .stats-value {
                    margin-top: 5px;
                }
                #kills-details {
                    cursor: pointer;
                }
            }
        }
        > div.col-md-6:nth-of-type(2) {
            padding-bottom: 50px;
            display: flex;
            align-items: center;
            .play-section-buttons {
                margin-top: 0px;
                padding-bottom: 5px;
                #active-token-title {
                    margin-top: 5px;
                    color: white;
                    font-size: 14px;
                }
                .claim-boom {
                    line-height: 22px;
                    font-size: 18px;
                }
                #stake-and-unstake-box {
                    margin-top: 20px;
                    padding: 10px;
                    box-shadow: 0 0 3px 4px rgba(255,255,255,0.8);
                    background-color: rgba(1, 1, 1, 0.7);
                }
            }
            div.rewards {
                margin-top: 55px;
                background-color: rgba(18, 6, 25, 0.9);
                border-radius: 5px;
                padding-bottom: 20px;
                width: 100%;
                >div:first-of-type {
                    color: lightgreen;
                    font-size: 20px;
                    #db-balances {
                        color: white;
                        box-shadow: 0 0 3px 4px rgba(255,255,255,0.2);
                        padding: 8px;
                    }
                }
                .btn-claim {
                    margin-top: 20px;
                    box-shadow: 0 0 3px 4px rgba(255,255,255,0.2);
                    padding: 5px;
                }
                #claim-boom-container {
                    background-color: #011627;
                    // display: grid; place-items: center;  
                    max-width: 320px;
                    margin: auto;
                    padding: 0px;
                    #claim-result {
                        color: white;
                        padding-top: 10px;
                        font-size: 14px;
                        #tx-id {
                            font-size: 10px;
                        }
                    }
                    #claim-disclaimer {
                        line-height: 22px;
                    }
                }

                #claim-boom-container .fake-a {
                    position: relative;
                    display: inline-block;
                    align-items: center;
                    padding: 0px;
                    height: 100%;
                    max-width: 320px;
                    height: 100%;
                    color: white;
                    font-size: 16px;
                    text-decoration: none;
                    overflow: hidden;
                    transition: .5s;
                    // text-transform: uppercase; letter-spacing: 4px;
                    padding: 10px;
                }
                
                .fake-a:hover {
                    background: #50E3C2;
                    border-radius: 5px;
                    outline: none;
                    border: 1px solid transparent;
                    box-shadow: 0 0 5px rgba(80,227,194,0.8), 0 0 25px rgba(80,227,194,0.8), 0 0 50px rgba(80,227,194,0.8), 0 0 100px rgba(80,227,194,0.8);
                }
                .fake-a span {
                    position: absolute;
                    display: block;
                }
                
                .fake-a span:nth-child(1) {
                    top: 0;
                    left: -100%;
                    width: 100%;
                    height: 2px;
                    background: linear-gradient(90deg, transparent, gold);
                    animation: btn-anim1 1s linear infinite;
                }

                @keyframes btn-anim1 {
                    0% {
                        left: -100%;
                    }
                    50%,100% {
                        left: 100%;
                    }
                }
                
                .fake-a span:nth-child(2) {
                    top: -100%;
                    right: 0;
                    width: 2px;
                    height: 100%;
                    background: linear-gradient(180deg, transparent, gold);
                    animation: btn-anim2 1s linear infinite;
                    animation-delay: .25s
                }
                
                @keyframes btn-anim2 {
                    0% {
                        top: -100%;
                    }
                    50%,100% {
                        top: 100%;
                    }
                }
                
                .fake-a span:nth-child(3) {
                    bottom: 0;
                    right: -100%;
                    width: 100%;
                    height: 2px;
                    background: linear-gradient(270deg, transparent, gold);
                    animation: btn-anim3 1s linear infinite;
                    animation-delay: .5s
                }
                
                @keyframes btn-anim3 {
                    0% {
                        right: -100%;
                    }
                    50%,100% {
                        right: 100%;
                    }
                }
                
                .fake-a span:nth-child(4) {
                    bottom: -100%;
                    left: 0;
                    width: 2px;
                    height: 100%;
                    background: linear-gradient(360deg, transparent, gold);
                    animation: btn-anim4 1s linear infinite;
                    animation-delay: .75s
                }
                
                @keyframes btn-anim4 {
                    0% {
                        bottom: -100%;
                    }
                    50%,100% {
                        bottom: 100%;
                    }
                }

                sub.claim-boom {
                    color: white;
                    font-size: 14px;
                }
                #unstaking-options {
                    margin-top: 20px;
                    .check-box {
                        background-color: white;
                    }
                    .active {
                        background-color: rgb(23, 129, 120);
                    }
                    label {
                        color: white;
                        margin-top: 10px;
                    }
                    #unstake-notes {
                        padding-top: 5px;
                        margin-left: -10px;
                    }
                }
                #unstake-amount-chk {
                    height: 40px;
                }
                #unstake-more {
                  font-size: 13px;
                  font-weight: 600;
                  text-align: left;
                  color: white;
                  margin-top: 10px;
                  margin-left: 0px;
                }
                #unstake-rules {
                    color: white;
                    font-size: 12px;
                }
                button {
                    font-size: 18px;
                }
            }
        }
    }
    #level-choice {
        position: absolute;
        button {
          color: white !important;
          border: thin solid rgba(255,255,255,0.5);
          font-size: 24px;
        }
        #level-1-btn {
            background: url(https://files.peakd.com/file/peakd-hive/crypto-shots/EoeDvv6yQr7W2rGv1QoVp9pJvBXfaLaNqmzndFvCfbziwmimb331mWQ1qieY8rd6yic.png) no-repeat center;
            background-size: cover;
            height: 50px;
        }
        #level-2-btn {
            background: url(https://files.peakd.com/file/peakd-hive/crypto-shots/23vhsRNpSobFjQ9t2ZWAdms5VuCWha7vmPThD6chneyK8ejyrx57eEEvcN4JtZHGYJnqy.png) no-repeat center;
            background-size: cover;
            height: 50px;
        }
        #level-3-btn {
            background: url(https://files.peakd.com/file/peakd-hive/crypto-shots/23vhsRNpSobFjQ9t2ZWAdms5VuCWha7vmPThD6chneyK8ejyrx57eEEvcN4JtZHGYJnqy.png) no-repeat center;
            background-size: cover;
            height: 50px;
        }
    }
    #traveling {
        padding: 10px;
        background: url(https://files.peakd.com/file/peakd-hive/crypto-shots/23xKJL2jBDk9PTHjHAEN8tosgeZELkH31QZx4ETLuKT7rzFtYM2fWLXmj8ftVqG41C3tv.jpg) no-repeat center;
        background-size: cover;
        text-align: center;
        #fares-title {
            margin: auto;
            font-size: 28px;
            font-family: monospace;
            color: white;
            width: 50%;
        }
        #requirements {
            margin-top: -10px;
            margin-left: 30px;
            text-align: left;
            color: white !important;
            background-color: rgba(1,1,1,0.5);
            font-size: 15px;
            ul {
                text-align: left;
            }
            h4 { font-size: 17px; }
        }
        .spaceship-choice {
            background-color: rgba(1,1,1,0.4);
            color: white;
            text-align: center;
            margin-top: 0px;
            button {
                font-size: 16px;
                color: black;
            }
            .spaceship-pane {
                min-height: 600px;
                border: thin solid rgba(255,255,255,0.2);
                padding: 10px;
                .row {
                    background-color: rgba(255,255,255,0.4);
                    color: black;
                    margin: 5px;
                }
                img {
                    background-size: cover;
                    width: 100%;
                    max-height: 280px;
                    max-width: 280px;
                }
                #free-bus {
                    max-height: 248px;
                }
                button {
                    margin-top: 20px;
                    min-width: 140px;
                    display: inline;
                    margin-left: 5px;
                }
                .row { height: 25%; }
                .parts-ownership {
                    font-size: 11px;
                    color: blue !important;
                }
                .sell-pass {
                    font-size: 13px;
                    margin-top: 25px;
                    a {
                        color: white;
                        font-weight: bold;
                    }
                    background-color: rgba(51,20,100,0.85);
                    padding: 4px;
                }
                .warning {
                    color: orange;
                    font-size: 14px;
                    padding: 5px;
                    #damn-captain {
                        font-weight: bolder;
                        font-size: 18px;
                    }
                }
                .spaceship-name {
                    font-size: 22px;
                    // a { color: rgb(5, 5, 93) !important; }
                }
                .wait-time {
                    font-weight: bold;
                    // font-size: 16px;
                }
                .engine-type {
                    font-size: 14px;
                    font-style: italic;
                }
            }
        }
        #loading-map {
            width: 80%;
            opacity: 0.8;
        }
        #time-to-destination {
            text-align: center;
            background-color: rgba(71, 98, 192, 0.6);
            color: black;
            font-family: Monospace;
            border-radius: 20px;
            font-size: 18px;
        }
    }
    .red { color: red; }
    .green { color: greenyellow; }

    .pane-under-claim-btn {
        color: #7bffff !important;
        line-height: 18px;
        padding: 5px;
        border: thin solid rgba(9, 118, 142, 0.3) !important;
        margin-bottom: 10px;
        width: 80%;
        margin: auto;
        a {
            color: white;
        }
    }
    .pane-under-play-btn {
        color: #7bffff !important;
        line-height: 18px;
        padding: 5px;
        background-color: rgba(9, 118, 142, 0.3) !important;
        width: 80%;
        font-size: 15px;
        margin: auto;
        a {
            color: white;
        }
    }
}

@media(max-width:768px) { 
    .play.container {
        max-width: 95%;
        .inner-container {
            background-size: cover !important;
        }
        >div>div.row {
            overflow-y: scroll;
            background-size: 200%;
            > div.col-md-6:first-of-type {
                height: unset;
            }
        }
    }
    #level-choice {
        display: none;
    }
}
